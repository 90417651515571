<template>
  <div>
    <under-development />
  </div>
</template>

<script>
import UnderDevelopment from '@/components/placeholder/UnderDevelopment.vue'

export default {
  name: 'ViewOrganizationRiders',
  components: { UnderDevelopment },
  data() {
    return {
      fleet: {},
    }
  },
  computed: {
    page() {
      return this.$route.params.page
    },
  },
  async created() {},
}
</script>

<style lang="scss"></style>
